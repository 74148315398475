import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Review from "../../components/review/Review";

import { HeartIcon } from "@heroicons/react/solid";
import OutlineHeartIcon from "@heroicons/react/outline/HeartIcon";
import { useToken } from "../../config/useToken";
import Config from "../../config/config";
import { useNavigate } from "react-router-dom";
import useAuth from "../../config/AuthContext";

let counter = 0;
let filteredImagesLen;
let filteredDetails = [];
let timer;

const Product = () => {
  const { productId } = useParams();
  //const [productId,setProductId]=useState(useParams().productId);
  const [product, setProduct] = useState(null);
  const [images, setImages] = useState([]);
  const [details, setDetails] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState(false);
  const [pincodeSuccess, setPincodeSuccess] = useState(false);
  const { addToCart, checkIfInWishList, addToWishList, removeFromWishList } =
    useToken();
  const [addedToWishlist, setaddedToWishlist] = useState(false);

  //customisedProduct
  const [customisedProduct, setCustomisedProduct] = useState(false);
  const [showProductDetails, setShowProductDetails] = useState(true);

  const navigation = useNavigate();
  const { selectedAddress } = useAuth();
  const checkAvailabilityBtnRef = useRef();

  const getProduct = async () => {
    await axios
      .get(`${Config.GLOBAL_URL}/api/products/${productId}`)
      .then((res) => {
        setProduct(res.data.data.data);
        checkForProductInWishList(res.data.data.data);
        if ("Customized Gifts" === res.data.data.data.category) {
          setCustomisedProduct(true);
        } else {
          setCustomisedProduct(false);
        }
        setImages(res.data.data.data.colors[0].images);
        setSelectedColor(res.data.data.data.colors[0].name);
        filteredImagesLen = res.data.data.data.colors[0].images.length;
        filteredDetails = res.data.data.data.array.filter(
          (arr) => arr.value !== null
        );
      })
      .then(() => {
        setDetails(filteredDetails);
      })
      .finally(() => {
        setLoading(false);
        if (selectedAddress) {
          console.log("----selected", selectedAddress);
          setPincode(selectedAddress.pin_code);
          checkAvailabilityBtnRef?.current?.click();
        }
      });
  };

  const checkForProductInWishList = async (pro) => {
    try {
      var a = await checkIfInWishList(pro);
      setaddedToWishlist(a);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleAddToWishList = () => {
    let pro = productId;
    // console.log(pro)
    // pro.id = productId;
    if (addedToWishlist) {
      removeFromWishList(pro);
      setaddedToWishlist(false);
    } else {
      addToWishList(pro);
      setaddedToWishlist(true);
    }
  };

  const buyNow = () => {
    const cartProduct = {
      // id: `${productId}${selectedColor}${selectedSize}`,
      id: `${productId}`,
      name: product.name,
      quantity: 1,
      price: (product.price * (100 - product.discount)) / 100,
      size: selectedSize,
      color: selectedColor,
      colorId: getColorId(selectedColor, product.colors),
      image: images,
      pincode: pincode,
      mrp: product.price,
      seller: `${product?.seller?.first_name} ${product?.seller?.last_name}`,
      deliveredBy: product.delivered_by,
      deliveryCharge: product.delivery_charge,
    };
    addToCart(cartProduct);
    navigation("/check-out");
  };

  const slideRef = useRef();

  useEffect(() => {
    getProduct();
    return () => {
      try {
        clearInterval(timer);
      } catch (err) {
        console.log(err);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useParams().productId]);

  useEffect(() => {
    startSlider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useParams().productId]);

  const startSlider = () => {
    setInterval(() => {
      counter = (counter + 1) % filteredImagesLen;
      setCurrentIndex(counter);
    }, 4000);
  };

  const getColorId = (color, colors) => {
    console.log(colors);
    let id = 0;
    colors.forEach((col) => {
      if (col.name === color) {
        id = col.id;
      }
    });
    return id;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="spinner-border h-12 w-12 rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <>
      <Header />

      {/* Customised product header */}
      <div className=" product-page flex w-screen max-w-7xl pl-4 mt-14 mx-auto">
        {customisedProduct && (
          <h1 className="mt-14 text-xl font font-semibold tracking-tight text-gray-600">
            Customise Your Product
          </h1>
        )}
      </div>

      <div
        id="product-body"
        className=" product-page flex w-screen max-w-7xl mx-auto"
      >
        <div className="flex-3 m-4">
          <div className="sticky top-16 bottom-0">
            <div
              ref={slideRef}
              id="image-container"
              className="bg-white w-full rounded shadow-md border-slate-700 select-none relative"
              style={{
                boxShadow: "0px 0px 15px -10px black",
                height: "calc(100vh - 200px)",
                // width:'fit-content',
                // margin:'auto'
              }}
            >
              <div className="w-full h-full flex justify-center items-center p-4">
                <img
                  className="object-contain h-full w-full"
                  src={images[currentIndex]}
                  alt=""
                />
                <div
                  onClick={handleAddToWishList}
                  style={{
                    position: "absolute",
                    top: "2rem",
                    right: "2rem",
                    cursor: "pointer",
                  }}
                >
                  {!addedToWishlist && (
                    <OutlineHeartIcon
                      style={{ color: "rgb(128 128 128)" }}
                      className="h-10 w-10"
                    />
                  )}
                  {addedToWishlist && (
                    <HeartIcon style={{ color: "red" }} className="h-10 w-10" />
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between h-24 w-full p-1 border-2 rounded-md my-4">
              {images.map((img, index) => {
                return (
                  <div
                    key={index}
                    className={`flex justify-center items-center p-2 h-full w-20 ${
                      index === currentIndex
                        ? "rounded border-2 border-weHandCraftBlue"
                        : ""
                    }`}
                    onMouseEnter={() => {
                      setCurrentIndex(index);
                      slideRef.current.classList.add("fade-anim");
                    }}
                  >
                    <img className="object-cover h-full" src={img} alt="" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="flex-4 m-4">
          {/* Top right card */}
          <div
            id="description-container"
            className="w-full px-10 py-5 shadow-md rounded-md"
          >
            {/* First row */}
            <div
              className="flex first-row"
              style={{ justifyContent: "space-between" }}
            >
              <div id="name_and_brand" style={{ maxWidth: "50%" }}>
                <h1 className="text-lg font font-bold tracking-tight text-gray-400">
                  {product.brand}
                </h1>
                <h1 className="text-lg mb-6 font font-semibold tracking-tight text-gray-600">
                  {product.name}
                </h1>
              </div>
            </div>

            {/* Second row for lined product cost , product cost and discount  */}
            <span
              className=" second-row mr-6 text-2xl font font-medium tracking-tight"
              style={
                product.discount > 0 && product.discount !== null
                  ? {
                      textDecoration: "line-through",
                      color: "rgb(107 114 128)",
                    }
                  : {
                      textDecoration: "none",
                      color: "black",
                    }
              }
            >
              ₹{product.price}
            </span>

            {/* Second with discount and product price  */}
            {product.discount > 0 && product.discount !== null && (
              <span className="second-row-discount">
                <span className="mr-4 text-2xl font font-semibold tracking-tight text-gray-900 ">
                  ₹{product.price - (product.discount * product.price) / 100}
                </span>
                <span className="text-lg font font-semibold tracking-tight text-gray-900">
                  {product.discount}% off
                </span>
              </span>
            )}

            {/* Third row out of stock */}
            {product.inventory === 0 && (
              <>
                <br />
                <span
                  style={{ color: "red" }}
                  className="text-lg  font font-semibold tracking-tight text-gray-900 third-row"
                >
                  Out of stock
                </span>
              </>
            )}

            {/* Fourth row colours */}
            <div className="flex mt-4 flex-row items-center fourth-row ">
              {product.colors.length > 0 && (
                <h1 className="mr-4 my-4 text-sm font-semibold tracking-tight text-gray-600">
                  COLORS:
                </h1>
              )}
              {product.colors.length > 0 && (
                <div className="flex flex-row items-center">
                  {product.colors.map((color) => (
                    <div
                      className="flex flex-col justify-center items-center h-14 w-14 p-1 border-2 rounded-md mr-3 cursor-pointer"
                      key={color.id}
                      onClick={() => {
                        if (selectedColor === color.name) {
                          setSelectedColor("");
                        } else {
                          setSelectedColor(color.name);
                          setImages(color.images);
                          setCurrentIndex(0);
                          filteredImagesLen = color.images.length;
                        }
                      }}
                      style={
                        selectedColor === color.name
                          ? { borderColor: "rgb(17 24 39)" }
                          : {}
                      }
                    >
                      <img
                        className="h-full w-auto"
                        src={color.images[0]}
                        alt=""
                      />
                    </div>
                  ))}
                </div>
              )}
              {product.colors.length > 0 && (
                <h1 className="mr-4 my-4 text-xs font-medium tracking-tight text-gray-600">
                  {selectedColor}
                </h1>
              )}
            </div>

            {/* Fifth row size */}
            <div className="flex flex-row items-center mt-4">
              {product.sizes.length > 0 && (
                <div className="flex items-center">
                  <h1 className="mr-4 text-sm font-semibold tracking-tight text-gray-600">
                    SIZE:
                  </h1>
                  <div className="flex flex-row items-center">
                    {product.sizes.map((size) => (
                      <div
                        key={size.size}
                        className="flex justify-center items-center h-9 w-9 border-2 rounded-md mr-3 text-sm"
                        onClick={() =>
                          size.available ? (
                            selectedSize === size.size ? (
                              setSelectedSize("")
                            ) : (
                              setSelectedSize(size.size)
                            )
                          ) : (
                            <></>
                          )
                        }
                        style={{
                          borderColor: size.available
                            ? "rgb(17 24 39)"
                            : "rgb(209 213 219)",
                          color:
                            size.available && size.size === selectedSize
                              ? "white"
                              : size.available
                              ? "rgb(17 24 39)"
                              : "rgb(209 213 219)",
                          backgroundColor:
                            size.size === selectedSize
                              ? "rgb(17 24 39)"
                              : "white",
                          cursor: size.available ? "pointer" : "not-allowed",
                        }}
                      >
                        {size.size}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {!selectedSize && (
              <div className="text-xs mt-1 text-red-600">
                Please select a size!
              </div>
            )}

            {/* Sixth row Pincode */}
            <div className="sixth-row flex flex-row items-center mt-4 mb-4">
              <h1 className="mr-4 text-sm font-semibold tracking-tight text-gray-600">
                PIN CODE:
              </h1>
              {/* PINCODE INPUT */}
              <div className="flex flex-row items-center">
                <input
                  className="py-1 px-2 border-2 rounded-md"
                  style={
                    pincodeError
                      ? { borderColor: "rgb(239 68 68)" }
                      : pincodeSuccess
                      ? { borderColor: "rgb(28, 64, 221)" }
                      : { borderColor: "rgb(209 213 219)" }
                  }
                  type="text"
                  placeholder="Enter Pincode"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                />
                <button
                  ref={checkAvailabilityBtnRef}
                  className="ml-4 py-2 px-3 bg-weHandCraftPrimary text-white font-semibold text-sm rounded-md"
                  onClick={() => {
                    if (pincode.length === 6) {
                      if (
                        product.servicible_pincodes.find(
                          (o) => o.pincode === pincode
                        )
                      ) {
                        setPincodeError(false);
                        setPincodeSuccess(true);
                      } else {
                        setPincodeError(true);
                        setPincodeSuccess(false);
                      }
                    } else {
                      setPincodeError(true);
                      setPincodeSuccess(false);
                    }
                  }}
                >
                  Check Availability
                </button>
              </div>
            </div>

            {/* Seventh row Pincode error */}
            {pincodeError ? (
              <div className="flex flex-row items-center">
                <p className="text-xs font-medium text-red-500">
                  Pincode not available
                </p>
              </div>
            ) : pincodeSuccess ? (
              <div className="flex flex-row items-center">
                <p className="text-xs font-medium text-indigo-500">
                  Available for Delivery!
                </p>
              </div>
            ) : (
              <></>
            )}

            {/* Eleventh row  */}
            <div className="flex flex-row w-full h-14 py-2 my-4 eleventh-row">
              <button
                className="flex-1 border-2 mr-2 border-weHandCraftBlue text-indigo-500 font font-semibold tracking-tight text-sm rounded-md"
                disabled={
                  product.sizes.length > 0
                    ? selectedSize === "" ||
                      selectedColor === "" ||
                      pincode.length !== 6 ||
                      product.inventory === 0
                    : selectedColor === "" ||
                      pincode.length !== 6 ||
                      product.inventory === 0
                }
                style={{
                  backgroundColor: (
                    product.sizes.length > 0
                      ? selectedSize === "" ||
                        selectedColor === "" ||
                        pincode.length !== 6 ||
                        product.inventory === 0
                      : selectedColor === "" ||
                        pincode.length !== 6 ||
                        product.inventory === 0
                  )
                    ? "grey"
                    : "white",
                  color: (
                    product.sizes.length > 0
                      ? selectedSize === "" ||
                        selectedColor === "" ||
                        pincode.length !== 6 ||
                        product.inventory === 0
                      : selectedColor === "" ||
                        pincode.length !== 6 ||
                        product.inventory === 0
                  )
                    ? "white"
                    : "rgb(28, 64, 221)",
                  borderColor: (
                    product.sizes.length > 0
                      ? selectedSize === "" ||
                        selectedColor === "" ||
                        pincode.length !== 6 ||
                        product.inventory === 0
                      : selectedColor === "" ||
                        pincode.length !== 6 ||
                        product.inventory === 0
                  )
                    ? "grey"
                    : "rgb(28, 64, 221)",
                }}
                onClick={() => {
                  const cartProduct = {
                    // id: `${productId}${selectedColor}${selectedSize}`,
                    id: `${productId}`,
                    name: product.name,
                    quantity: 1,
                    price: (product.price * (100 - product.discount)) / 100,
                    size: selectedSize,
                    color: selectedColor,
                    colorId: getColorId(selectedColor, product.colors),
                    image: images,
                    pincode: pincode,
                    mrp: product.price,
                    seller: `${product?.seller?.first_name} ${product?.seller?.last_name}`,
                    deliveredBy: product.delivered_by,
                    deliveryCharge: product.delivery_charge,
                  };
                  addToCart(cartProduct);
                }}
              >
                ADD TO CART
              </button>
              <button
                className="flex-1 ml-2 bg-weHandCraftPrimary text-white font font-semibold tracking-tight text-sm rounded-md"
                disabled={
                  product.sizes.length > 0
                    ? selectedSize === "" ||
                      selectedColor === "" ||
                      pincode.length !== 6 ||
                      product.inventory === 0
                    : selectedColor === "" ||
                      pincode.length !== 6 ||
                      product.inventory === 0
                }
                onClick={() => buyNow()}
              >
                BUY NOW
              </button>
            </div>

            {/* Twelvth Product Details Collapsable */}
            <button
              className="accordion-button relative flex items-center w-full border-0 rounded-none transition focus:outline-none collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
              onClick={() => setShowProductDetails(!showProductDetails)}
            >
              <h1 className="accordion-header text-2xl text-gray-900 my-1 font font-semibold tracking-tight">
                Product Details
              </h1>
              <span
                style={{
                  rotate: showProductDetails ? "-180deg" : "0deg",
                  transition: "all 0.3s ease-in-out",
                }}
                class={`-me-1 ms-auto h-5 w-5 shrink-0 rotate-[${
                  showProductDetails ? "-180deg" : "0deg"
                }] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </button>

            {/* Thirtheenth collaps open */}
            <div
              id="collapseOne"
              className={`accordion-collapse ${
                showProductDetails ? "" : "collapse"
              }`}
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body pt-4">
                <table className="table-fixed w-full">
                  <thead>
                    <tr>
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {details.map((detail) => (
                      <tr className="h-10" key={detail.title}>
                        <td className="font-semibold text-gray-500">
                          {detail.title}
                        </td>
                        <td className="font-semibold text-gray-800">
                          {detail.value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Rating and reviews */}
          <div
            id="feedback-container"
            className="w-full p-4 mt-4 shadow-md rounded"
            style={{
              boxShadow: "0px 0px 15px -10px black",
            }}
          >
            <h1 className="text-lg font font-bold tracking-tight text-gray-600">
              Ratings & Reviews
            </h1>
            {product.reviews.length === 0 ? (
              <div className="flex flex-col items-center my-5 justify-center">
                <img
                  className="object-cover h-32 w-32"
                  src="https://i.ibb.co/8zGR9R1/undraw-Notify-re-65on.png"
                  alt=""
                />
                <h1 className="text-sm tracking-tight text-gray-400">
                  Be the first to review this product
                </h1>
              </div>
            ) : (
              product?.reviews.map((review) => {
                return (
                  <Review
                    key={`${review.from}${review.heading}`}
                    start={review.start}
                    heading={review.heading}
                    body={review.body}
                    images={review.images}
                    from={review.from}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Product;
