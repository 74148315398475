import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ProductCard = ({ id, name, price, sizes, brand, colors, discount }) => {
  const navigate = useNavigate();
  const [image, setImage] = useState("");

  React.useEffect(() => {
    setImage(colors[0].image_1);
  }, [colors]);

  const handleColorHover = (idx) => {
    setImage(colors[idx].image_2);
  };

  return (
    <div
      className="flex flex-col w-full min-h-20 px-4 py-2 rounded border border-gray-100 shadow-sm cursor-pointer"
      onClick={() => navigate(`/product/${id}`)}
    >
      <div className="flex-3 mb-2">
        <div className="flex h-full w-full justify-center items-center">
          <img
            src={image}
            alt="Product"
            className="h-40 mx-auto object-cover object-center"
          />
        </div>
      </div>
      <div className="flex-1">
        <h3 className="text-gray-500 font-semibold text-sm">{brand}</h3>
        <h3 className="mb-2 text-ellipsis truncate">{name}</h3>
        {discount > 0 ? (
          <div className="flex gap-2 md:gap-4">
            <h3 className="font-medium text-gray-500 line-through mb-2">
              ₹{price}
            </h3>
            <h3 className="font-medium text-gray-900 mb-2">
              ₹{(price * (100 - discount)) / 100}
            </h3>
            <h3 className="font-medium text-sm text-gray-900 mb-2">{discount}% Off</h3>
          </div>
        ) : (
          <h3 className="font-bold mb-2">₹{price}</h3>
        )}
        {sizes !== null && (
          <div className="text-sm mb-1">
            <span className="text-gray-400 text-sm">Sizes: </span>
            &nbsp;
            {sizes.map((sizes, idx) => (
              <span key={idx} className="text-sm">
                {sizes + ", "}
              </span>
            ))}
          </div>
        )}
        {colors.length !== 0 && (
          <div className="text-sm">
            <div className="flex items-center">
              <span className="text-gray-400 text-sm">Color: </span>
              &nbsp;
              {colors.map((color, idx) => (
                <React.Fragment key={idx}>
                  <div
                    id={idx}
                    className="w-3.5 h-3.5 rounded-full"
                    style={{ backgroundColor: color.hex_code }}
                    onMouseEnter={() => handleColorHover(idx)}
                    onMouseLeave={() => setImage(colors[idx].image_1)}
                  />
                  &nbsp;
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
