import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import axios from "axios";
import { useToken } from "./useToken";
import { initialState, reducer } from "./useReducer";
import Config from "./config";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { setToken, setUser, removeToken, removeUser, getToken, getUser } = useToken();
  const [loginError, setLoginError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const register = async (
    firstName,
    lastName,
    email,
    password,
    phone_number
  ) => {
    await axios
      .post(`${Config.GLOBAL_URL}/api/auth/register`, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        phone_number: phone_number,
      })
      .then((res) => {
        console.log(res);
        navigate("/login");
      })
      .catch(async (error) => {
        console.log(error.response);
      });
  };

  const login = async (email, password) => {
    var errorMsg = "";
    await axios
      .post(`${Config.GLOBAL_URL}/api/auth/login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        console.log(res.data);
        setToken(res.data.data.token);
        setUser(res.data.data.user);
        setSelectedAddress(res.data.data.user.selected_address);
        setLoading(false);
        navigate("/");
      })
      .catch(async (error) => {
        errorMsg =
          error.response.data.errors.message ??
          error.response.data.errors.detail ??
          "Invalid credential";
        setLoginError(error.response.data.error);
        setLoading(false);
      });
    return errorMsg;
  };

  const logout = async () => {
    dispatch({ type: "USER", payload: false });
    removeToken();
    removeUser();
    window.location.href = "/login";
  };

  const changePassword = async (oldPass, newPass) => {
    await axios.put(
      `${Config.GLOBAL_URL}/api/auth/change-password`,
      {
        new_password: newPass,
        old_password: oldPass,
      },
      {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
  };

  const getProfileData = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${getToken()}`,
      },
    };
    try {
      const res = await axios.get(`${Config.GLOBAL_URL}/api/auth/profile`, config);
      setUser(res.data.data);
      setSelectedAddress(res.data.data.selected_address);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const checkFunc = async () => {
      setLoading(true);
      if (
        localStorage.getItem("token") !== "" &&
        localStorage.getItem("user") !== null
      ) {
        dispatch({ type: "USER", payload: true });
      } else {
        dispatch({ type: "USER", payload: false });
      }
      setLoading(false);
    };
    checkFunc();
    if (getUser()) {
      getProfileData();
    }
    // eslint-disable-next-line
  }, []);

  const memoedValue = useMemo(
    () => ({
      login,
      logout,
      dispatch,
      register,
      changePassword,
      selectedAddress,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, loginError, state, login, logout, changePassword, selectedAddress]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {loading ? <>Loading!</> : !loading && children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
