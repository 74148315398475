import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Config from "../../config/config";

const HelpShipping = () => {
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(true);

  const getHelpShipping = async () => {
    try {
      const res = await axios.get(`${Config.GLOBAL_URL}/api/policy/shipping/`);
      setBody(res.data.data[0]?.content);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getHelpShipping();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="spinner-border h-12 w-12 rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="flex min-h-screen w-screen max-w-7xl md:mt-14 mt-20 mx-auto py-8">
        <div className="flex flex-col">
          <div dangerouslySetInnerHTML={{ __html: body }}></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HelpShipping;
