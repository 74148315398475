import { useLocation, Navigate } from "react-router-dom";
import { useToken } from "../config/useToken";

export const PublicRoute = ({ children }) => {
  let { getUser } = useToken();
  let location = useLocation();

  if (getUser()) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};
