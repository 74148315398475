import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useToken } from "../../config/useToken";
import useAuth from "../../config/AuthContext";
import axios from "axios";
import Config from "../../config/config";
import { useNavigate } from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";

const AddressSelectionModal = ({
  showAddressSelectionModal,
  setShowAddressSelectionModal,
}) => {
  const { getUser, getToken } = useToken();
  const { selectedAddress } = useAuth();
  const navigate = useNavigate();

  const closeModal = () => {
    setShowAddressSelectionModal(false);
  };

  const updateSelectedAddress = async (newSelectedAddress) => {
    try {
      const token = getToken();
      const user = getUser();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      };
      const res = await axios.patch(
        `${Config.GLOBAL_URL}/api/auth/profile`,
        {
          selected_address: newSelectedAddress,
          first_name: user.first_name,
          last_name: user.last_name,
          phone_number: user.phone_number,
        },
        config
      );
      console.log(res.data.data);
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      closeModal();
    }
  };

  return (
    <Transition appear show={showAddressSelectionModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all">
                <div className="bg-slate-200 p-4 flex justify-between items-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-700"
                  >
                    Choose your location
                  </Dialog.Title>
                  <XIcon
                    className="h-6 w-6 text-slate-600 cursor-pointer"
                    onClick={closeModal}
                  />
                </div>
                <div className="mt-2 px-4 py-3">
                  <p className="text-xs text-gray-500">
                    Select a delivery location to see product availability
                  </p>
                </div>

                <div className="p-3 flex flex-col items-start justify-center gap-3">
                  {getUser()?.address?.map((address, index) => (
                    <button
                      key={index}
                      type="button"
                      className={`inline-flex flex-col w-full justify-center rounded-md border border-transparent bg-slate-50 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-slate-100 hover:border-slate-400 focus:outline-none outline-none ${
                        selectedAddress?.id === address?.id
                          ? " border-weHandCraftBlue border-2"
                          : ""
                      }`}
                      onClick={() => updateSelectedAddress(address.id)}
                    >
                      <div className="flex flex-col items-start w-full">
                        <div className="font-semibold flex items-center justify-between w-full">
                          <span>
                            {address.name}
                            {selectedAddress &&
                              selectedAddress.id === address.id && (
                                <span className="text-white bg-blue-500 text-2xs mx-3 px-2 py-1 rounded-2xl">
                                  Default
                                </span>
                              )}
                          </span>
                          <div className="border border-slate-300 rounded px-2 font-normal">
                            {address.address_type}
                          </div>
                        </div>
                        <div className=" font-normal">
                          {address.address_line_1}
                          {address.address_line_2
                            ? `, ${address.address_line_2}`
                            : ""}
                          , {address.city}, {address.state}, {address.country},{" "}
                          {address.pin_code}
                        </div>
                      </div>
                    </button>
                  ))}
                </div>

                <div className="border-b border-b-slate-400 mt-1 mx-4 mb-2"></div>

                <div className="my-4 flex justify-center items-center mx-3">
                  <button
                    className=" bg-weHandCraftPrimary w-full rounded p-1 text-white hover:bg-blue-600"
                    onClick={() => navigate("/profile/address")}
                  >
                    Add New Address
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddressSelectionModal;
