import React, { useEffect, useState } from "react";
import Carousel from "../../components/carousel/Carousel";
import CategoryTab from "../../components/categoryTab/CategoryTab";
import Footer from "../../components/footer/Footer";
import CategoryHeader from "../../components/header/CategoryHeader";
import Header from "../../components/header/Header";
import PromoBanner from "../../components/promo/PromoBanner";
import axios from "axios";
import "../../styles/home/home.css";
import Config from "../../config/config";

const Home = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    const res = await axios.get(`${Config.GLOBAL_URL}/api/ads/subcategory-ads`);
    setData(res.data.data);
    console.log(res.data.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div id="Home" className="pt-14 ">
      <Header />
      <CategoryHeader />
      <Carousel />
      <PromoBanner />
      {!loading &&
        data.map((item) => {
          if (item.products.length > 0) {
            return (
              <CategoryTab
                key={item.id}
                id={item.id}
                name={item.name}
                image={item.image}
                products={item.products}
                slug={item.slug}
              />
            );
          }
          return <></>;
        })}
      <Footer />
    </div>
  );
};

export default Home;
