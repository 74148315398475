import React, { useState, useEffect } from "react";
import { useToken } from "../../config/useToken";

export default function Orders() {
  const { getOrders, addReview } = useToken();

  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [starRating, setStarRating] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [description, setDescription] = useState("");
  const [productId, setProductId] = useState(null);
  const [allOrderLoading, setAllOrderLoading] = useState(false);

  const getAllOrders = async () => {
    setAllOrderLoading(true);
    try {
      const res = await getOrders();
      setAllOrders(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setAllOrderLoading(false);
    }
  };

  useEffect(() => {
    getAllOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFileChange = (event) => {
    // Update the state
    setSelectedFile(event.target.files[0]);
  };

  const reset = () => {
    setProductId(null);
    setDescription("");
    setStarRating(0);
    setSelectedFile(null);
  };

  const uploadReviews = async () => {
    setLoading(true);
    var res = await addReview({
      content: description,
      rating: starRating,
      image: selectedFile,
      PRODUCT_ID: productId,
    });
    if (res) {
      setShowModal(false);
      reset();
    }
    setLoading(false);
  };

  return (
    <div className="h-full w-full flex flex-col p-6">
      <div className="flex items-center mb-4">
        <h1 className="text-base font-semibold m-0">Orders</h1>
      </div>
      {allOrderLoading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div className="spinner-border h-12 w-12 rounded-full animate-spin" />
        </div>
      )}
      {allOrders.map((dat) => {
        return (
          <div
            id="orders-product-page"
            style={{ marginBottom: "3rem", overflow: "hidden", width: "80%" }}
            className="flex flex-row px-4 py-2 rounded border border-gray-100 shadow-md cursor-pointer"
          >
            <div
              style={{
                height: "100%",
                flex: "1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="mb-2"
            >
              <div
                style={{ height: "80%", width: "80%" }}
                className="flex justify-center items-center"
              >
                <img
                  src={dat.image}
                  alt="imh"
                  className="max-h-40 mx-auto object-cover object-center"
                />
              </div>
            </div>

            <div
              style={{
                paddingLeft: "2rem",
                paddingRight: "2rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              className="flex-1"
            >
              <div className="text-base font-semibold mt-4">{dat.product}</div>
              <div>
                <div className="font-medium text-gray-900 mt-2 mb-1">
                  {`Quantity : ${dat.quantity}`}
                </div>

                <div className="font-medium text-gray-900 mb-1">
                  {`Order Id : ${dat.id}`}
                </div>
                <div className="font-medium text-gray-900 mb-1">
                  {`Amount : ${dat.amount}`}
                </div>
              </div>
            </div>

            <div
              id="btn-cont"
              style={{ display: "flex", flexDirection: "column-reverse" }}
              className="flex-1"
            >
              <div
                className="mb-2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div className="text-base font-semibold mr-4">Cancel</div>
                <button
                  className="bg-weHandCraftBlue text-white text-sm font-medium py-2 px-4 rounded w-full "
                  onClick={() => console.log("track order")}
                >
                  Track Order
                </button>
              </div>
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <button
                  className="bg-weHandCraftBlue text-white text-sm font-medium py-2 px-4 rounded w-full "
                  onClick={() => {
                    setShowModal(true);
                    setProductId(dat.product_id);
                  }}
                >
                  Add Reviews
                </button>
              </div>
            </div>
          </div>
        );
      })}

      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Add Review</h3>
                </div>
                {/*body*/}
                <div className="w-full bg-white px-8 pt-6 mb-4">
                  <div className="mb-6">
                    {showStarRating(starRating, setStarRating)}
                  </div>

                  <div className="mb-6">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="password"
                    >
                      Upload File (optional)
                    </label>
                    <input
                      onChange={onFileChange}
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      className=" appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  {selectedFile && (
                    <div className="mb-4" style={{ width: "10rem" }}>
                      <img src={URL.createObjectURL(selectedFile)} alt="yes" />
                    </div>
                  )}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="username"
                    >
                      Description (optional)
                    </label>
                    <textarea
                      onChange={(e) => setDescription(e.target.value)}
                      className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      rows="3"
                      placeholder="Enter your text here..."
                    />
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      reset();
                    }}
                    disable={loading}
                  >
                    Close
                  </button>

                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      uploadReviews();
                    }}
                    disable={loading}
                  >
                    {!loading && "Save"}
                    {loading && (
                      <div className="spinner-border h-6 w-6 rounded-full animate-spin" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </div>
  );
}

const showStarRating = (a, setA) => {
  return (
    <ul className="flex">
      {[1, 2, 3, 4, 5].map((i) => {
        if (i <= a) {
          return (
            <li onClick={() => setA(i)}>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="star"
                className="w-6 text-yellow-500 mr-1"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  fill="currentColor"
                  d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                ></path>
              </svg>
            </li>
          );
        }
        return (
          <li onClick={() => setA(i)}>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="star"
              className="w-6 text-yellow-500 mr-1"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"
              ></path>
            </svg>
          </li>
        );
      })}
    </ul>
  );
};
