import React, { useState } from "react";
import useAuth from "../../config/AuthContext";
import Header from "../../components/header/Header";
import { Link } from "react-router-dom";
import WeHandCraftLogo from "../../assets/img/weHandCraftLogoWithoutBg.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const [loginError, setLoginError] = useState({ error: false, msg: "" });

  const handleLogin = async () => {
    var msg = await login(email, password);
    console.log("Message", msg);
    setLoginError({ error: true, msg });
  };

  return (
    <>
      <Header hideLogin={true} />
      <div id="login-page" className="flex h-screen w-screen">
        <div
          id="image-container"
          className="flex flex-1 border-2 border-r justify-center items-center md:flex-col mb-10 md:mb-0"
        >
          <img src={WeHandCraftLogo} alt="logo" className=" h-32 md:h-56" />
          <h1 className="text-3xl md:text-4xl text-we-hand-craft-blue font-bold italic">
            We-Handcraft
          </h1>
        </div>
        <div
          id="login-form"
          className="flex flex-1 items-center justify-center min-h-screen"
        >
          <div className="px-8 py-6 w-120 mt-4 text-left bg-white">
            <h3 className="text-2xl font-bold">Login to your account</h3>
            <div className="mt-4">
              <div>
                <label className="block" htmlFor="email">
                  Email
                </label>
                <input
                  type="text"
                  placeholder="Email"
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <label className="block">Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {loginError.error && (
                <div>
                  <span style={{ color: "red" }}>{loginError.msg}</span>
                </div>
              )}

              <div className="flex items-baseline mb-4 justify-between">
                <button
                  className="flex-1 px-6 py-2 mt-4 text-white bg-weHandCraftPrimary rounded-lg hover:bg-blue-900"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </div>
              <div className="flex justify-between">
                <Link
                  to="/forgot-password"
                  className="text-sm text-blue-600 hover:underline"
                >
                  Forgot password?
                </Link>
                <a
                  href="/register"
                  className="text-sm text-blue-600 hover:underline"
                >
                  New user? Sign up!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
