import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Config from "../../config/config";
import WeHandCraftLogo from "../../assets/img/weHandCraftLogoWithoutBg.png";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [otpResendTimer, setOtpResendTimer] = useState(60);
  const [emailVerificationId, setEmailVerificationId] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const sendOtp = async () => {
    try {
      const res = await axios.post(
        `${Config.GLOBAL_URL}/api/auth/email/send-otp`,
        {
          email: email,
        }
      );
      toast.success(res.data.data.message);
      setOtpSent(true);
      setEmailVerificationId(res.data.data.id);
    } catch (err) {
      console.error(err.response);
      toast.error(err.response.data.errors.message);
    } finally {
      setLoading(false);
    }
  };

  const verifyOtp = async () => {
    try {
      const res = await axios.post(
        `${Config.GLOBAL_URL}/api/auth/email/verify-otp`,
        {
          code: otp,
          id: emailVerificationId,
        }
      );
      toast.success(res.data.data.message);
      setOtpVerified(true);
    } catch (err) {
      console.error(err.response);
      toast.error(err.response.data.errors.message);
    } finally {
      setLoading(false);
    }
  };

  const changePassword = async () => {
    try {
      if (newPassword !== confirmNewPassword) {
        toast.error("New password and confim password do not match");
        return;
      }
      const res = await axios.post(
        `${Config.GLOBAL_URL}/api/auth/email/create-new-password`,
        {
          email: email,
          id: emailVerificationId,
          otp: otp,
          new_password: newPassword,
        }
      );
      toast.success(res.data.data.message);
      navigate("/login");
    } catch (err) {
      console.error(err.response);
      toast.error(err.response.data.errors.message);
    } finally {
      setLoading(false);
    }
  };

  const resendOtp = async () => {
    setLoading(true);
    setOtpSent(false);
    setOtpResendTimer(60);
    await sendOtp();
  };

  const handleClick = () => {
    setLoading(true);
    if (!otpSent) {
      sendOtp();
    } else if (!otpVerified) {
      verifyOtp();
    } else {
      changePassword();
    }
  };

  const buttonText = () => {
    if (!otpSent) {
      return "Send OTP";
    } else if (!otpVerified) {
      return "Verify OTP";
    } else {
      return "Change Password";
    }
  };

  useEffect(() => {
    let interval;
    if (otpSent && !otpVerified && otpResendTimer > 0) {
      interval = setInterval(() => {
        console.warn("-----------------------");
        setOtpResendTimer(otpResendTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [otpResendTimer, otpSent, otpVerified]);

  return (
    <>
      <Header hideLogin={true} />
      <div id="login-page" className="flex h-screen w-screen">
        <div
          id="image-container"
          className="flex flex-1 border-2 border-r justify-center items-center md:flex-col mb-10 md:mb-0"
        >
          <img src={WeHandCraftLogo} alt="logo" className=" h-32 md:h-56" />
          <h1 className="text-3xl md:text-4xl text-we-hand-craft-blue font-bold italic">
            We-Handcraft
          </h1>
        </div>
        <div
          id="login-form"
          className="flex flex-1 items-center justify-center min-h-screen"
        >
          <div className="px-8 py-6 w-120 mt-4 text-left bg-white">
            <h3 className="text-2xl font-bold">Forgot Password</h3>
            <div className="mt-4">
              <div>
                <label className="block" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  placeholder="Email"
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none"
                  value={email}
                  readOnly={otpSent}
                  disabled={otpSent}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {otpSent && !otpVerified && (
                <div className="mt-4">
                  <label className="block">OTP</label>
                  <input
                    type="text"
                    placeholder="OTP"
                    value={otp}
                    maxLength={6}
                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none"
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  <div className="mt-1">
                    <p className="text-sm">
                      If you didn't receive the otp, click{" "}
                      <span
                        className={`font-bold hover:cursor-pointer ${
                          otpResendTimer > 0
                            ? "text-indigo-400"
                            : "text-indigo-700"
                        }`}
                        onClick={() => resendOtp()}
                      >
                        Resend otp
                      </span>{" "}
                      {otpResendTimer > 0 && (
                        <>
                          in <span>{otpResendTimer}</span>
                        </>
                      )}
                    </p>
                  </div>
                </div>
              )}
              {otpVerified && (
                <>
                  <div className="mt-4">
                    <label className="block">New Password</label>
                    <input
                      type="password"
                      placeholder="New Password"
                      value={newPassword}
                      className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none"
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block">Confirm New Password</label>
                    <input
                      type="password"
                      placeholder="Confirm New Password"
                      value={confirmNewPassword}
                      className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none"
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                  </div>
                </>
              )}

              <div className="flex items-baseline mb-4 justify-between">
                <button
                  className="flex-1 px-6 py-2 mt-4 text-white bg-weHandCraftPrimary rounded-lg hover:bg-blue-900 justify-center items-center flex"
                  onClick={handleClick}
                >
                  {loading ? (
                    <div class="border-gray-300 h-6 w-6 animate-spin rounded-full border-2 border-t-weHandCraftBlue" />
                  ) : (
                    buttonText()
                  )}
                </button>
              </div>
              <div className="flex justify-between">
                <Link
                  to={"/login"}
                  className="text-sm text-blue-600 hover:underline"
                >
                  Remember password? Login!
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
