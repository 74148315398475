import React, { useEffect } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import AddressCard from "./AddressCard";
import { useToken } from "../../config/useToken";
import States from "../../config/States";
import { Cities } from "../../config/Cities";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015

const ManageAddress = ({ addressesData }) => {
  useEffect(() => {
    console.log(States);
  }, []);

  const initialAddState = {
    address_type: "",
    name: "",
    phone_number: "",
    address_line_1: "",
    address_line_2: "",
    city: Cities[States[0]][0],
    state: States[0],
    pin_code: "",
    country: "",
  };

  const { addAddress } = useToken();
  // eslint-disable-next-line no-unused-vars
  const [addresses, setAddresses] = React.useState(addressesData);
  const [address, setAddress] = React.useState(initialAddState);
  const [showAddAddress, setShowAddAddress] = React.useState(false);

  return (
    <div id="manageAddressPage" className="h-full w-full flex flex-col p-6">
      <div className="flex items-center mb-4">
        <h1 className="text-base font-semibold m-0">Manage Addresses</h1>
      </div>
      <button
        className="flex items-center gap-1 font-medium uppercase text-blue-700 text-sm text-left py-4 px-6 border"
        onClick={() => setShowAddAddress(true)}
      >
        <PlusIcon className="h-6 w-6" />
        Add New Address
      </button>
      {showAddAddress && (
        <div className="border p-6 my-4">
          <div className="flex flex-col flex-wrap h-72">
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                Type
              </label>
              <select
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                value={address.address_type}
                onChange={(e) => {
                  setAddress({ ...address, address_type: e.target.value });
                  console.log(e.target.value);
                }}
              >
                <option value="">Select</option>
                <option value="Home">Home</option>
                <option value="Office">Office</option>
              </select>
            </div>
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                Name
              </label>
              <input
                type="text"
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                placeholder="Enter Name"
                value={address.name}
                onChange={(e) => {
                  setAddress({ ...address, name: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                Phone
              </label>
              <input
                type="tel"
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                placeholder="Enter Phone"
                maxLength={10}
                value={address.phone_number}
                onChange={(e) => {
                  setAddress({ ...address, phone_number: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                Address Line 1
              </label>
              <input
                type="text"
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                placeholder="Enter Address Line 1"
                value={address.address_line_1}
                onChange={(e) => {
                  setAddress({ ...address, address_line_1: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                Address Line 2
              </label>
              <input
                type="text"
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                placeholder="Enter Address Line 2"
                value={address.address_line_2}
                onChange={(e) => {
                  setAddress({ ...address, address_line_2: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                City
              </label>
              {/* <input
                type="text"
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                placeholder="Enter City"
                value={address.city}
                onChange={(e) => {
                  setAddress({ ...address, city: e.target.value });
                }}
              /> */}
              <Typeahead
                className="border w-1/2 rounded-sm text-sm autocomplete-type-ahead"
                onChange={(selected) => {
                  setAddress({ ...address, city: selected[0] });
                }}
                id="svfvddewedvddv"
                //selected={address.city}
                selected={address?.city ? [address.city] : undefined}
                placeholder="City"
                options={Cities?.[address.state] || []}
              />
            </div>
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                State
              </label>
              {/* <input
                type="text"
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                placeholder="Enter State"
                value={address.state}
                onChange={(e) => {
                  setAddress({ ...address, state: e.target.value });
                }}
              /> */}
              <Typeahead
                className="border w-1/2 rounded-sm text-sm autocomplete-type-ahead"
                onChange={(selected) => {
                  setAddress({
                    ...address,
                    state: selected[0],
                    city: Cities[selected?.[0]]?.[0] || "",
                  });
                }}
                id="svfvdfvddv"
                placeholder="State"
                options={States}
              />
            </div>
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                Country
              </label>
              <input
                type="text"
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                placeholder="Enter Country"
                value={address.country}
                onChange={(e) => {
                  setAddress({ ...address, country: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-row gap-1 items-center mb-2">
              <label className="text-sm font-medium text-gray-700 w-2/6">
                PIN CODE
              </label>
              <input
                type="text"
                className="border w-1/2 py-2 px-3 rounded-sm text-sm"
                placeholder="Enter PIN CODE"
                value={address.pin_code}
                onChange={(e) => {
                  setAddress({ ...address, pin_code: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="flex justify-between items-center w-full">
            <button
              className="text-gray-700 border border-gray-700 text-sm font-medium py-2 px-4 rounded w-1/2 mt-4 mr-2"
              onClick={() => {
                setShowAddAddress(false);
              }}
            >
              Cancel
            </button>
            <button
              className="bg-weHandCraftPrimary text-white text-sm font-medium py-2 px-4 rounded w-1/2 mt-4 ml-2"
              onClick={() => {
                console.log(address);
                setShowAddAddress(false);
                addAddress(address);
              }}
            >
              Save Address
            </button>
          </div>
        </div>
      )}
      {addresses?.length > 0 &&
        addresses.map((address) => (
          <div className="border">
            <AddressCard key={address.id} addressData={address} />
          </div>
        ))}
    </div>
  );
};

export default ManageAddress;
