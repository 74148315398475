import React from "react";
import { Navigate, useLocation } from "react-router";
import { useToken } from "../config/useToken";

const PrivateRoute = ({ children }) => {
  let { getUser } = useToken();
  let location = useLocation();

  if (!getUser()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

export default PrivateRoute;
