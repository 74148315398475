import React, { useState, useEffect } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import {
  LibraryIcon,
  LoginIcon,
  UserCircleIcon,
  ShoppingCartIcon,
  HeartIcon,
  ShoppingBagIcon,
  LocationMarkerIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useToken } from "../../config/useToken";
import Cart from "../../pages/cart/Cart";
import WeHandCraftLogo from "../../assets/img/weHandCraftLogoWithoutBg.png";
import useAuth from "../../config/AuthContext";
import AddressSelectionModal from "./AddressSelectionModal";

const Header = ({ hideLogin }) => {
  const [cartOpen, setCartOpen] = useState(false);
  const navigation = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAddressSelectionModal, setShowAddressSelectionModal] =
    useState(false);
  const { getUser } = useToken();
  const { selectedAddress } = useAuth();

  useEffect(() => {
    setUser(getUser());
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        id="header-lg"
        className="flex top-0 z-50 justify-between fixed w-screen bg-weHandCraftBlue h-14 drop-shadow shadow-md"
      >
        <div id="header-gutter" className="h-full min-w-1"></div>
        <div className="flex justify-between h-full w-full max-w-7xl">
          <div className="flex justify-evenly items-center">
            {/* logo */}
            <div
              id="logo-box"
              className="flex h-full w-64 justify-between items-center"
            >
              <NavLink to="/" className={"flex items-center"}>
                <img src={WeHandCraftLogo} alt="logo" className="h-14 mr-4" />
                <h1 className="text-we-hand-craft-blue font-bold text-xl italic">
                  We-Handcraft
                </h1>
              </NavLink>
            </div>

            {selectedAddress && (
              <div
                className="flex items-center gap-2 transition-transform duration-300 hover:outline-slate-200 hover:rounded hover:outline p-1 px-3 cursor-pointer"
                onClick={() => setShowAddressSelectionModal(true)}
              >
                <LocationMarkerIcon className="h-5 w-5 text-slate-600" />
                <div className="flex justify-center flex-col">
                  <p className=" text-xs text-slate-600 h-3">
                    Deliver to{" "}
                    <span className="capitalize">
                      {selectedAddress.name.split(" ")[0]}
                    </span>
                  </p>
                  <p className="text-sm font-semibold text-slate-600">
                    {selectedAddress.city}, {selectedAddress.pin_code}
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* search box */}
          <div
            className="flex justify-center items-center h-full my-0 min-w-18"
            style={{
              width: "calc(100% - 800px)",
            }}
          >
            <div className="w-full max-w-xl h-9 py-0 px-2 flex">
              <input
                type="text"
                className="rounded-l bg-slate-100 border-none outline-none h-full py-0 px-3 text-sm placeholder:text-slate-500"
                placeholder="Search for products, brands and more"
                style={{
                  width: "calc(100%)",
                }}
              />
              <button className="rounded-r bg-slate-100 border-none outline-none cursor-pointer h-full w-9 p-0">
                <SearchIcon className="text-blue-600 h-5 w-5" />
              </button>
            </div>
          </div>

          <div className="flex justify-evenly items-center">
            {/* Become a seller */}
            <div
              className="ml-5 flex justify-center items-center font-medium text-slate-600 cursor-pointer"
              onClick={() => {}}
            >
              <Link
                to="/become-a-seller"
                className="px-2 py-1 rounded flex items-center gap-1"
              >
                <LibraryIcon className="text-slate-600 h-5 w-5" />
                Be a Seller
              </Link>
            </div>

            {/* Login */}
            <div className="w-16 mx-5 flex justify-center items-center font-medium text-white relative header__profile_dropdown_container">
              {user !== null ? (
                <button
                  className="text-slate-600 ml-4 px-2 py-1 rounded-sm font-medium capitalize flex items-center gap-1 transition-transform duration-300 header__profile_dropdown_btn"
                  id="options-menu"
                >
                  <UserCircleIcon className="text-slate-600 h-5 w-5" />
                  {user.first_name}
                  <ChevronDownIcon className="text-slate-600 h-5 w-5 transition-transform duration-300 header__profile_dropdown_icon" />
                </button>
              ) : (
                !loading &&
                (!hideLogin ? (
                  <button
                    className="text-slate-600 ml-3 px-8 py-1 rounded-sm font-medium flex items-center gap-1"
                    onClick={() => navigation("/login")}
                  >
                    <LoginIcon className="text-slate-600 h-5 w-5" />
                    Login
                  </button>
                ) : (
                  <></>
                ))
              )}
              <div className="header__profile_dropdown hidden absolute -left-8 top-7 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <Link
                    to="/profile"
                    className="text-gray-700 px-4 py-2 text-sm hover:bg-gray-200 flex items-center gap-2"
                    role="menuitem"
                    tabIndex="-1"
                    id="menu-item-0"
                  >
                    <UserCircleIcon className="text-slate-600 h-4 w-4" />
                    Profile
                  </Link>
                  <Link
                    to="/profile/orders"
                    className="text-gray-700 px-4 py-2 text-sm hover:bg-gray-200 flex items-center gap-2"
                    role="menuitem"
                    tabIndex="-1"
                    id="menu-item-0"
                  >
                    <ShoppingBagIcon className="text-slate-600 h-4 w-4" />
                    Orders
                  </Link>
                  <Link
                    to="/profile/wishlist"
                    className="text-gray-700 px-4 py-2 text-sm hover:bg-gray-200 flex items-center gap-2"
                    role="menuitem"
                    tabIndex="-1"
                    id="menu-item-0"
                  >
                    <HeartIcon className="text-slate-600 h-4 w-4" />
                    Wishlist
                  </Link>
                </div>
              </div>
            </div>

            {/* Cart */}
            {!hideLogin && (
              <div
                className="w-16 ml-4 flex justify-center items-center gap-1 font-medium text-slate-600 cursor-pointer"
                onClick={() => {
                  if (!cartOpen) {
                    setCartOpen(!cartOpen);
                    console.log("Open cart", cartOpen);
                  }
                }}
              >
                <ShoppingCartIcon className="h-5 w-5" />
                Cart
              </div>
            )}
          </div>
        </div>
        <div className="h-full min-w-1"></div>
        <AddressSelectionModal
          showAddressSelectionModal={showAddressSelectionModal}
          setShowAddressSelectionModal={setShowAddressSelectionModal}
        />
      </div>
      {
        <div
          id="header-md"
          className="top-0 z-50 justify-between fixed w-screen bg-weHandCraftBlue drop-shadow"
        >
          <div className="flex h-12 justify-between w-full items-center">
            {/* logo */}
            <div
              id="logo-box"
              className="flex h-full w-full items-center gap-5"
            >
              <NavLink to="/" className="flex items-center">
                <img src={WeHandCraftLogo} alt="logo" className="h-8 mr-1" />
                <h1 className=" text-we-hand-craft-blue font-bold text-xl italic">
                  We-Handcraft
                </h1>
              </NavLink>
              {selectedAddress && (
                <div
                  className="flex items-center gap-2 transition-transform duration-300 hover:outline-slate-200 hover:rounded hover:outline p-1 cursor-pointer"
                  onClick={() => setShowAddressSelectionModal(true)}
                >
                  <LocationMarkerIcon className="h-5 w-5 text-slate-600" />
                  <p className="text-sm font-semibold text-slate-600">
                    {selectedAddress.pin_code}
                  </p>
                </div>
              )}
            </div>
            <div className="flex">
              {/* Cart */}
              <div
                style={{ width: "2rem" }}
                className="flex justify-center items-center font-medium text-slate-600 cursor-pointer mr-2"
                onClick={() => {
                  if (!cartOpen) {
                    setCartOpen(!cartOpen);
                  }
                }}
              >
                <ShoppingCartIcon className="h-5 w-5 mr-2" />
              </div>

              {/* Login */}
              {!hideLogin && (
                <div className="w-16 mr-5 flex justify-center items-center font-medium text-slate-600 relative header__profile_dropdown_container">
                  {user !== null ? (
                    <button
                      className="text-slate-600 ml-3 px-2 py-1 rounded-sm font-medium capitalize flex items-center gap-1 transition-transform duration-300 header__profile_dropdown_btn"
                      id="options-menu-phone"
                      aria-expanded="true"
                      aria-haspopup="true"
                    >
                      <UserCircleIcon className="text-slate-600 h-5 w-5" />
                      {user.first_name}
                      <ChevronDownIcon className="text-slate-600 h-5 w-5 transition-transform duration-300 header__profile_dropdown_icon" />
                    </button>
                  ) : (
                    !loading &&
                    (!hideLogin ? (
                      <button
                        className="text-slate-600 mx-0 py-1 rounded-sm font-medium flex items-center gap-1"
                        onClick={() => navigation("/login")}
                      >
                        <LoginIcon className="text-slate-600 h-5 w-5" />
                        Login
                      </button>
                    ) : (
                      <></>
                    ))
                  )}
                  <div className="header__profile_dropdown hidden absolute -left-14 top-8 z-100 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu-phone"
                    >
                      <Link
                        to="/profile"
                        className="text-gray-700 px-4 py-2 text-sm hover:bg-gray-200 flex items-center gap-2"
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-phone-0"
                      >
                        <UserCircleIcon className="text-slate-600 h-4 w-4" />
                        Profile
                      </Link>
                      <Link
                        to="/profile/orders"
                        className="text-gray-700 px-4 py-2 text-sm hover:bg-gray-200 flex items-center gap-2"
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-phone-1"
                      >
                        <ShoppingBagIcon className="text-slate-600 h-4 w-4" />
                        Orders
                      </Link>
                      <Link
                        to="/profile/wishlist"
                        className="text-gray-700 px-4 py-2 text-sm hover:bg-gray-200 flex items-center gap-2"
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-phone-2"
                      >
                        <HeartIcon className="text-slate-600 h-4 w-4" />
                        Wishlist
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Search */}
          <div className="flex justify-between h-full w-full max-w-7xl">
            <div
              className="flex justify-center items-center h-full my-0 min-w-18"
              style={{
                width: "100%",
              }}
            >
              <div className="w-full max-w-xl h-9 py-0 px-2 flex">
                <input
                  type="text"
                  className="rounded-l-sm bg-slate-100 border-none outline-none h-full py-0 px-3 text-sm placeholder:text-slate-500"
                  placeholder="Search for products, brands and more"
                  style={{
                    width: "calc(100%)",
                  }}
                />
                <button className="rounded-r-sm bg-slate-100 border-none outline-none cursor-pointer h-full w-9 p-0">
                  <SearchIcon className="text-indigo-500 h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      }
      <Cart open={cartOpen} setOpen={setCartOpen} />
    </>
  );
};

export default Header;
