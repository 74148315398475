import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";

const BecomeASeller = () => {
  return (
    <div className="pt-14">
      <Header />
      <div className="flex flex-col md:flex-row w-full px-6 md:px-24 py-10">
        <div className="flex flex-col w-full justify-center font-bold">
          <div className="text-xl md:text-2xl">Start Selling on</div>
          <div className="text-3xl md:text-5xl py-4">
            India's First E-commerce platform for Handycraft
          </div>
          <div className="text-3xl md:text-5xl">with GI tagged and Non-GI manufacturers</div>
          <div className="text-xl md:text-2xl pt-4">
            55 million+ Monthly Active Users
          </div>
          <div className="py-8">
            <Link
              to={"https://admin.wehandcraft.com/vendor"}
              target="_blank"
              className="bg-we-hand-craft-blue px-6 py-4 rounded text-white text-lg"
            >
              Register
            </Link>
          </div>
        </div>
        <div className="flex w-full">
          <img
            className="w-full h-auto rounded-l-full"
            src="https://myntrascmuistatic.myntassets.com/partner-assets/partnerportal/landingpage/HeroImageBottle.webp"
            alt=""
          />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center mb-8">
        <div className="text-2xl flex flex-col items-center justify-center py-6 font-semibold">
          Start Selling In 4 Simple Steps
          <div className="bg-blue-500 w-14 h-[2px]"></div>
        </div>
        <div className="flex flex-col md:flex-row px-6 md:px-24 gap-4 pt-6 flex-wrap">
          <div className="flex flex-col flex-1 justify-center items-center bg-rose-100 rounded p-8">
            <p className="text-xl font-semibold">Register</p>
            <p className="text-md text-center pt-8 ">
              Find all the{" "}
              <span className="font-semibold">onboarding requirements</span> to
              create your account here.
            </p>
          </div>
          <div className="flex flex-col flex-1 justify-center items-center bg-amber-100 rounded p-8">
            <p className="text-xl font-semibold">Sell</p>
            <p className="text-md text-center pt-8 ">
              Learn all about{" "}
              <span className="font-semibold">fulfilment models</span>, platform
              integration & <span className="font-semibold">prerequisites</span>{" "}
              for operational readiness here.
            </p>
          </div>
          <div className="flex flex-col flex-1 justify-center items-center bg-green-100 rounded p-8">
            <p className="text-xl font-semibold">Earn</p>
            <p className="text-md text-center pt-8 ">
              Get{" "}
              <span className="font-semibold">secure & timely payments</span> on
              predefined days. Find out about the payment cycle.
            </p>
          </div>
          <div className="flex flex-col flex-1 justify-center items-center bg-sky-100 rounded p-8">
            <p className="text-xl font-semibold">Grow</p>
            <p className="text-md text-center pt-8 ">
              Get <span className="font-semibold">tailored support</span> at
              every step to steer your business.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BecomeASeller;
