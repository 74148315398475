import axios from "axios";
import Config from "./config";

export const useToken = () => {
  const getToken = () => {
    return localStorage.getItem("token");
  };

  const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };

  const setToken = (token) => {
    localStorage.setItem("token", token);
  };

  const setUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
  };

  const removeToken = () => {
    localStorage.removeItem("token");
  };

  const removeUser = () => {
    localStorage.removeItem("user");
  };

  const editPersonalInfo = async (first_name, last_name, gender) => {
    const user = getUser();
    const token = getToken();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    const res = await axios.patch(
      `${Config.GLOBAL_URL}/api/auth/profile`,
      {
        first_name,
        last_name,
        gender,
        phone_number: user.phone_number,
      },
      config
    );
    setUser(res.data.data);
  };

  const getOrders = async () => {
    const token = getToken();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    const res = await axios.get(
      `${Config.GLOBAL_URL}/api/auth/payment/billing`,

      config
    );
    return res;
  };

  const editUserPhone = async (phone_number) => {
    const user = getUser();
    const token = getToken();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    const res = await axios.patch(
      `${Config.GLOBAL_URL}/api/auth/profile`,
      {
        first_name: user.first_name,
        last_name: user.last_name,
        gender: user.gender,
        phone_number,
      },
      config
    );
    console.log(res.data.data);
    setUser(res.data.data);
  };

  const isUserLoggedIn = () => {
    return getToken() !== null;
  };

  const sendBargainRequest = async (productId, bargainValue, product) => {
    const token = getToken();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    axios
      .post(
        `${Config.GLOBAL_URL}/api/bargain`,
        {
          product_id: productId,
          asked_price: bargainValue,
        },
        config
      )
      .then((res) => {
        console.log(res.data.data);
        addToCart(product);
        return "Accepted and added to cart";
      })
      .catch((err) => {
        console.log(err.response.data.errors.message, "err");
        return err.response.data.errors.message;
      });
  };

  //validate the coupon
  const validateCoupon = async (code) => {
    const token = getToken();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    const res = await axios.get(
      `${Config.GLOBAL_URL}/api/auth/payment/coupon-code?code=${code}`,
      config
    );
    return res.data.data;
  };

  //check out api
  const checkOut = async (bill) => {
    const token = getToken();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    await axios
      .post(`${Config.GLOBAL_URL}/api/auth/payment/billing`, bill, config)
      .then((res) => {
        console.log(res.data.data);
        return true;
      })
      .catch((err) => {
        return false;
      });
    return true;
  };

  //all wishlist functionality
  const addToWishList = async (product) => {
    let wishList = await getWishList();
    let flag = 0;
    wishList = wishList.map((item) => {
      if (item.id === product) {
        flag = 1;
      }
      return item;
    });
    if (flag === 1) {
      console.log("Item already present in wishlist");
    } else {
      console.log("Item not present in wishlist so added");
      wishList.push(parseInt(product));
      setWishList(wishList);
    }
  };

  const removeFromWishList = async (product) => {
    let wishList = await getWishList();
    console.log("Befor Wishlist", wishList);
    wishList = wishList.filter((item) => item.id !== parseInt(product));
    console.log("After Wishlist", wishList);
    setWishList(wishList);
  };

  const getWishList = async () => {
    var items = [];
    await axios
      .get(`${Config.GLOBAL_URL}/api/auth/wishlist`, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        items = data.data.data;
      }).catch((err) => {
        console.log(err.response);
      })
    return items.products;
  };

  const setWishList = async (wishList) => {
    localStorage.setItem("wishList", JSON.stringify(wishList));
    let res = wishList.map((a) => {
      console.log(a.id);
      return a.id ? a.id : a;
    });

    await axios
      .post(
        `${Config.GLOBAL_URL}/api/auth/wishlist`,
        {
          product: res,
        },
        {
          headers: {
            Authorization: `Token ${getToken()}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => console.log(res))
      .catch((err) => {
        console.log(err.response);
      });
  };

  const checkIfInWishList = async (product) => {
    var wishListItems = await getWishList();
    var flag = 0;
    wishListItems.forEach((item) => {
      if (item?.name === product?.name) {
        flag = 1;
      }
    });
    return flag === 1 ? true : false;
  };

  //all cart functionality

  const addToCart = (product) => {
    let cart = getCart();
    let flag = 0;
    cart = cart.map((item) => {
      if (item.id === product.id && item.price === product.price) {
        item.quantity += 1;
        flag = 1;
      }
      return item;
    });
    if (flag === 1) {
      setCart(cart);
    } else {
      cart.push(product);
      setCart(cart);
    }
  };

  const getCart = () => {
    return JSON.parse(localStorage.getItem("cart")) || [];
  };

  const setCart = async (cart) => {
    localStorage.setItem("cart", JSON.stringify(cart));
    await axios.post(
      `${Config.GLOBAL_URL}/api/auth/cart`,
      {
        products: cart,
      },
      {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
  };

  const wallet = async () => {
    const token = getToken();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    const res = await axios.get(
      `${Config.GLOBAL_URL}/api/auth/wallet`,

      config
    );
    return res.data.data;
  };

  const removeCartItem = (product) => {
    let cart = getCart();
    cart = cart.filter((item) => item.id !== product.id);
    setCart(cart);
  };

  const increaseQuantity = (product) => {
    let cart = getCart();
    cart = cart.map((item) => {
      if (item.id === product.id) {
        item.quantity += 1;
      }
      return item;
    });
    setCart(cart);
  };

  const decreaseQuantity = (product) => {
    if (product.quantity > 1) {
      let cart = getCart();
      cart = cart.map((item) => {
        if (item.id === product.id) {
          item.quantity -= 1;
        }
        return item;
      });
      setCart(cart);
    } else {
      removeCartItem(product);
    }
  };

  const addAddress = async (address) => {
    const user = getUser();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${getToken()}`,
      },
    };
    const res = await axios.post(
      `${Config.GLOBAL_URL}/api/auth/address`,
      {
        address_line_1: address.address_line_1,
        address_line_2: address.address_line_2,
        city: address.city,
        state: address.state,
        country: address.country,
        pin_code: address.pin_code,
        name: address.name,
        phone_number: address.phone_number,
        address_type: address.address_type,
      },
      config
    );
    await user.address.push(res.data.data);
    setUser(user);
    window.location.reload();
  };

  const editAddress = async (address) => {
    const user = getUser();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${getToken()}`,
      },
    };
    const res = await axios.put(
      `${Config.GLOBAL_URL}/api/auth/address/${address.id}`,
      {
        address_line_1: address.address_line_1,
        address_line_2: address.address_line_2,
        city: address.city,
        state: address.state,
        country: address.country,
        pin_code: address.pin_code,
        name: address.name,
        phone_number: address.phone_number,
        address_type: address.address_type,
      },
      config
    );
    user.address = user.address.map((item) => {
      if (item.id === address.id) {
        item = res.data.data;
      }
      return item;
    });
    setUser(user);
    window.location.reload();
  };

  const deleteAddress = async (address) => {
    const user = getUser();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${getToken()}`,
      },
    };
    await axios.delete(
      `${Config.GLOBAL_URL}/api/auth/address/${address.id}`,
      config
    );
    user.address = user.address.filter((item) => item.id !== address.id);
    setUser(user);
    window.location.reload();
  };

  const addReview = async ({ content, rating, image, PRODUCT_ID }) => {
    const formData = new FormData();

    if (image) {
      formData.append("image_1", image);
    }
    if (content !== "") {
      formData.append("content", content);
    }

    formData.append("rating", rating);
    var ret_msg = false;
    await axios
      .post(`${Config.GLOBAL_URL}/api/review/${PRODUCT_ID}`, formData, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        ret_msg = true;
      })
      .catch((err) => {
        ret_msg = false;
      });
    return ret_msg;
  };

  return {
    getToken,
    getUser,
    setToken,
    setUser,
    removeToken,
    removeUser,
    isUserLoggedIn,
    sendBargainRequest,
    addToCart,
    getCart,
    removeCartItem,
    increaseQuantity,
    decreaseQuantity,
    editPersonalInfo,
    editUserPhone,
    addAddress,
    editAddress,
    deleteAddress,
    getWishList,
    addToWishList,
    checkIfInWishList,
    removeFromWishList,
    getOrders,
    validateCoupon,
    checkOut,
    setCart,
    wallet,
    addReview,
  };
};
