import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

import { CheckIcon } from "@heroicons/react/solid";
import CheckoutProductCart from "../../components/productCard/CheckoutProductCart";
import { useNavigate } from "react-router-dom";
import { useToken } from "../../config/useToken";
import Config from "../../config/config";
import axios from "axios";

export default function CheckOut() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const [discount, setDiscount] = useState(0);
  const [address, setAddress] = useState("");
  const [addresses, setAddresses] = useState("");
  const [paymentOption, setPaymentOption] = useState("Cash On Delivery");
  const [couponCode, setCouponCode] = useState("");
  const [showInvalidCouponCode, setShowInvalidCouponCode] = useState(false);
  const [balance, setBalance] = useState(0);
  const [walletCash, setWalletCash] = useState(0);
  const [resellingMargin, setResellingMargin] = useState(0);
  const [totalShippingCharge, setTotalShippingCharge] = useState(0);
  const [user, setUser] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [total, setTotal] = useState(0);

  const {
    getCart,
    removeCartItem,
    increaseQuantity,
    decreaseQuantity,
    getUser,
    validateCoupon,
    wallet,
    getToken,
    setCart,
  } = useToken();
  const navigate = useNavigate();

  const handleWalletChange = (event) => {
    let { value, min, max } = event.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    setWalletCash(value);
  };

  const calculateSubTotal = () => {
    setCartItems(getCart());
    let total = 0;
    let totShipCharge = 0;
    getCart().forEach((item) => {
      total += item.price * item.quantity;
      totShipCharge += item.deliveryCharge;
    });
    setSubTotal(total);
    setTotalShippingCharge(0);
    console.log(totShipCharge);
    // setTotalShippingCharge(totShipCharge);
  };

  const deleteItem = (product) => {
    setLoading(true);
    removeCartItem(product);
    setCartItems(getCart());
    calculateSubTotal();
    setLoading(false);
  };

  const payNow = async () => {
    setSubmitLoading(true);

    if (cartItems.length <= 0) {
      console.log("No Items in the cart");
    } else if (subTotal < 0) {
      console.log("Ammount cannot be zero");
    } else {
      const products = cartItems.map((itm) => {
        return {
          id: itm.id,
          amount: itm.price * itm.quantity,
          quantity: itm.quantity,
          color: itm.colorId || 0,
          size: itm.size || "",
          address_id: address.id + "" || "",
        };
      });
      const bill = {
        total_amount: total,
        coupon_code: couponCode,
        payment_mode: paymentOption,
        reselling: resellingMargin > 0,
        reselling_margin: resellingMargin,
        wallet_amount_used: walletCash,
        products,
      };
      // var res = await checkOut(bill);
      const token = getToken();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      };
      await axios
        .post(`${Config.GLOBAL_URL}/api/auth/payment/billing`, bill, config)
        .then(async (res) => {
          if (res.data.data.payment_mode === "Online") {
            const options = {
              key: "rzp_test_UZwq8kg6JXSlw3", // Enter the Key ID generated from the Dashboard
              amount: `${res.data.data.amount}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
              currency: "INR",
              name: "We-Handcraft",
              theme: {
                color: "#1c40dd",
              },
              image: "https://wehandcraft.com/static/media/weHandCraftLogoWithoutBg.cca71b53dc768533ffb5.png",
              description: "Payment for Order",
              order_id: `${res.data.data.order_id}`, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
              callback_url: `${Config.GLOBAL_URL}/api/auth/payment/success-payment`,
              prefill: {
                name: `${getUser().name}`,
                email: `${getUser().email}`,
                contact: `+91${getUser().phone_number}`,
              },
            };
            const rzp1 = new window.Razorpay(options);
            if (res.data.data.id !== null) {
              rzp1.open();
            }
          } else {
            await setCart([]);
            window.location.href = "/profile/orders";
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // if (res) {
      //   await setCart([]);
      //   navigate("/profile/orders");
      // }
      setSubmitLoading(false);
    }
  };

  const redeemCoupon = async () => {
    if (couponCode === "") {
      setShowInvalidCouponCode(false);
      setDiscount(0);
      return;
    }
    const res = await validateCoupon(couponCode);
    if (res?.discount) {
      setDiscount(res.discount);
    } else {
      console.log(res.data);
      setShowInvalidCouponCode(true);
    }
  };

  useEffect(() => {
    const getWallet = async () => {
      const res = await wallet();
      setBalance(res.balance ?? 0);
    };
    setCartItems(getCart());
    calculateSubTotal();
    setLoading(false);
    getWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const user = getUser();
    setUser(user);
    if (!user) {
      navigate(`/login`);
    } else {
      setAddresses(user.address);
      setAddress(user.address[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let totl = subTotal - (discount / 100) * subTotal;
    totl = totl + parseInt(resellingMargin) - parseInt(walletCash);
    setTotal(totl);
  }, [subTotal, discount, resellingMargin, walletCash]);

  return (
    <>
      <Header />
      <div className=" w-screen max-w-7xl mt-14 mx-auto">
        <h2
          style={{ paddingTop: "4rem", paddingBottom: "2rem" }}
          className=" px-4 text-2xl font font-extrabold tracking-tight text-gray-900"
        >
          Checkout
        </h2>

        <div className="flex checkout-page" style={{ width: "100%" }}>
          <div className="flex-4 m-4">
            <div
              style={{ boxShadow: "black 0px 0px 15px -10px" }}
              className="w-full px-10 py-6 shadow-md rounded-md"
            >
              <h2 className="mr-4 text-2xl font font-semibold tracking-tight text-gray-900">
                User Info
              </h2>
              <h2 className="mt-3 text-lg font tracking-tight text-gray-400">
                {user.phone_number}
              </h2>
              <h2 className="mt-3 text-lg font tracking-tight text-gray-400">
                {user.email}
              </h2>
              <div className="mt-6 border-t-2"></div>
              <h2 className="mt-6 text-2xl font font-semibold tracking-tight text-gray-900">
                Delivery Adderss
              </h2>
              <div
                id="delivery-address"
                style={{ justifyContent: "space-between" }}
                className="flex mt-3 "
              >
                <h2 className="text-lg font tracking-tight text-gray-400">
                  {address?.address_line_1}
                </h2>
                <button
                  onClick={() => setShowModal(true)}
                  className="px-6 py-2 bg-weHandCraftPrimary text-white rounded drop-shadow"
                >
                  Change
                </button>
              </div>
              <div className="mt-6 border-t-2"></div>
              <h2 className="mt-6 text-2xl font font-semibold tracking-tight text-gray-900">
                Order Summary
              </h2>

              {/* Products */}
              {cartItems.map((product) => {
                return (
                  <CheckoutProductCart
                    product={product}
                    increaseQuantity={increaseQuantity}
                    decreaseQuantity={decreaseQuantity}
                    calculateSubTotal={calculateSubTotal}
                    deleteItem={deleteItem}
                  />
                );
              })}

              <div
                id="input-container"
                style={{ marginTop: "4rem" }}
                className=" px-4 ml-8"
              >
                {/* Coupon Code */}
                <div id="coupon-code" className="flex">
                  <input
                    onChange={(e) => {
                      setCouponCode(e.target.value);
                    }}
                    value={couponCode}
                    type="text"
                    placeholder="Coupon Code"
                    className=""
                    style={{
                      display: "flex",
                      flex: "1",
                      border: "solid 1px",
                      paddingLeft: "1rem",
                      textTransform: "uppercase",
                    }}
                  />
                  <button
                    onClick={redeemCoupon}
                    className="px-8 ml-8 py-2 bg-weHandCraftPrimary text-white rounded drop-shadow"
                  >
                    Apply
                  </button>
                </div>
                {showInvalidCouponCode && (
                  <span style={{ color: "red" }}>Invalid coupon code</span>
                )}

                {/* Use wallet cash */}
                <div style={{ marginTop: "3rem" }}>
                  <div className="flex">
                    <h2 className=" text-2xl font font-semibold tracking-tight text-gray-900">
                      Use Wallet Cash
                    </h2>

                    <CheckIcon
                      style={{ color: "#fff", backgroundColor: "black" }}
                      className="h-6 w-6 ml-8"
                    />
                  </div>
                  <div className="mt-6">
                    <input
                      type="number"
                      min="0"
                      max={balance}
                      value={walletCash}
                      onChange={handleWalletChange}
                      placeholder="Enter Amount"
                      className="py-2"
                      style={{
                        display: "flex",
                        flex: "1",
                        border: "solid 1px",
                        paddingLeft: "1rem",
                        width: "15rem",
                      }}
                    />
                  </div>
                  <div className="mt-2">
                    <h2 style={{ color: "green" }}>({balance} Rs left)</h2>
                  </div>
                </div>

                {/* Reselling */}
                <div style={{ marginTop: "3rem" }}>
                  <div className="flex">
                    <h2 className=" text-2xl font font-semibold tracking-tight text-gray-900">
                      Reselling
                    </h2>

                    <CheckIcon
                      style={{ color: "#fff", backgroundColor: "black" }}
                      className="h-6 w-6 ml-8"
                    />
                  </div>
                  <div className="mt-6">
                    <input
                      value={resellingMargin}
                      type="number"
                      onChange={(e) => setResellingMargin(e.target.value)}
                      placeholder="Enter Margin"
                      className="py-2"
                      style={{
                        display: "flex",
                        flex: "1",
                        border: "solid 1px",
                        paddingLeft: "1rem",
                        width: "15rem",
                      }}
                    />
                  </div>
                  <div className="mt-2">
                    <h2 style={{ color: "green" }}>
                      You earn 600 profit on this account
                    </h2>
                    <h2 style={{ color: "green" }}>
                      (Earned profit will credit to your wallet)
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{}} className="flex-2 m-4">
            <div
              style={{ boxShadow: "black 0px 0px 15px -10px" }}
              className="w-full  py-6 shadow-md rounded-md"
            >
              <h2 className=" px-10 text-lg font font-semibold tracking-tight text-gray-400">
                Price Details
              </h2>
              <div className="mt-5 border-t-2"></div>
              <div className=" px-10 ">
                <div
                  style={{ justifyContent: "space-between" }}
                  className="flex  mt-6"
                >
                  <h2 className="">Price ( {cartItems.length} item)</h2>
                  <h2 className="">
                    {subTotal.toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                      style: "currency",
                      currency: "INR",
                    })}
                  </h2>
                </div>
                <div
                  style={{ justifyContent: "space-between" }}
                  className="flex mt-6 "
                >
                  <h2 className="">Discount</h2>
                  <h2 style={{ color: "green" }} className="">
                    {/* {discount.toLocaleString("en-IN", {
                                            maximumFractionDigits: 0,
                                            style: "currency",
                                            currency: "INR",
                                        })} */}
                    {discount} %
                  </h2>
                </div>
                <div
                  style={{ justifyContent: "space-between" }}
                  className="flex  mt-6"
                >
                  <h2 className="">Delivery Charges</h2>
                  <h2 className={`${!totalShippingCharge && "text-green-700"}`}>
                    {totalShippingCharge
                      ? totalShippingCharge.toLocaleString("en-IN", {
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: "INR",
                        })
                      : "FREE"}
                  </h2>
                </div>
                <div
                  style={{ justifyContent: "space-between" }}
                  className="flex  mt-6"
                >
                  <h2 className="">Margin</h2>
                  <h2 style={{ color: "green" }} className="">
                    {resellingMargin}
                  </h2>
                </div>
                <div
                  style={{ justifyContent: "space-between" }}
                  className="flex  mt-6"
                >
                  <h2 className="">Wallet amount use</h2>
                  <h2 style={{ color: "green" }} className="">
                    {walletCash}
                  </h2>
                </div>
                <div className="mt-6 border-t-2"></div>
                <div
                  style={{ justifyContent: "space-between" }}
                  className="flex  mt-6 text-lg font font-semibold tracking-tight"
                >
                  <h2 className="">Total Amount</h2>
                  <h2 style={{}} className="">
                    {total.toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                      style: "currency",
                      currency: "INR",
                    })}
                  </h2>
                </div>
                <div className="mt-6 border-t-2"></div>
              </div>
            </div>
            <div
              style={{ boxShadow: "black 0px 0px 15px -10px" }}
              className="w-full px-10 py-5 mt-4 shadow-md rounded-md"
            >
              <h2 className="mr-4 text-2xl font font-semibold tracking-tight text-gray-900">
                Pay Using
              </h2>
              <div className="flex space-between">
                <div>
                  <div className=" mt-6 form-check">
                    <input
                      onClick={() => setPaymentOption("Online")}
                      className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="text-gray-400 text-lg font font-semibold tracking-tight"
                      htmlFor="flexRadioDefault1"
                    >
                      Online
                    </label>
                  </div>
                  <div className="mt-6 border-t-2"></div>
                  <div className="form-check mt-6">
                    <input
                      onClick={() => setPaymentOption("Cash On Delivery")}
                      className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      checked
                    />
                    <label
                      className=" text-gray-400 text-lg font font-semibold tracking-tight"
                      htmlFor="flexRadioDefault2"
                    >
                      Cash On Delivery
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="w-100 mt-6 flex flex-end "
              style={{ flexDirection: "row-reverse" }}
            >
              {!submitLoading && (
                <button
                  onClick={payNow}
                  className="px-6 py-2 bg-weHandCraftPrimary text-white rounded drop-shadow"
                >
                  Pay Now
                </button>
              )}
              {submitLoading && (
                <button
                  disabled={true}
                  className="px-10 py-2 bg-weHandCraftPrimary text-white rounded drop-shadow"
                >
                  <div className="spinner-border h-6 w-6 rounded-full animate-spin" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Select the address</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  {addresses.map((add, i) => {
                    return (
                      <>
                        <div className=" mt-3 form-check">
                          <input
                            onClick={() => setAddress(add)}
                            className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="radio"
                            name="addressRadio"
                            id={add.id}
                          />
                          <label
                            className="text-gray-400 text-lg font font-semibold tracking-tight"
                            htmlFor={add.id}
                          >
                            {add.address_line_1}
                          </label>
                        </div>
                        {addresses.length !== i + 1 && (
                          <div className="mt-3 border-t-2"></div>
                        )}
                      </>
                    );
                  })}

                  {/* <div className="form-check mt-3">
                                        <input className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                            type="radio" name="addressRadio" id="addressRadio2" checked />
                                        <label className=" text-gray-400 text-lg font font-semibold tracking-tight" for="addressRadio2">
                                            Cash On Delivery
                                        </label>
                                    </div> */}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}

      <Footer />
    </>
  );
}
