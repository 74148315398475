import React, { useEffect, useState } from "react";
import { useToken } from "../../config/useToken";
import EmptyCart from "../../assets/img/empty_cart.svg";
import { useNavigate } from "react-router-dom";

const Wishlist = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getWishList, removeFromWishList } = useToken();
  const navigate = useNavigate();

  useEffect(() => {
    calculateSubTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems]);

  useEffect(() => {
    fetchAllCartItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateSubTotal = () => {};

  const fetchAllCartItems = async () => {
    //setLoading(true)
    try {
      var items = await getWishList();
      setCartItems(items);
      calculateSubTotal();
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const removeItem = async (product) => {
    setLoading(true);
    try {
      removeFromWishList(product);
      fetchAllCartItems();
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  return (
    <div className="h-full w-full flex flex-col p-6">
      <div className="flex items-center mb-4">
        <h1 className="text-base font-semibold m-0">Wishlist</h1>
      </div>
      {loading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div className="spinner-border h-12 w-12 rounded-full animate-spin" />
        </div>
      )}
      <div className="mt-8">
        <div className="flow-root">
          <ul className="-my-6 divide-y divide-gray-200">
            {cartItems?.length > 0 &&
              cartItems?.map((product) => (
                <li key={product.id} className="py-6 flex">
                  <div
                    onClick={() => {
                      navigate(`/product/${product.id}`);
                    }}
                    className="flex flex-shrink-0 w-24 h-24 border justify-center items-center border-gray-200 rounded-md overflow-hidden"
                  >
                    <img
                      src={product?.image}
                      alt="product-img"
                      className="max-w-full max-h-full object-center object-cover"
                    />
                  </div>

                  <div className="ml-4 flex-1 flex flex-col">
                    <div
                      onClick={() => navigate(`/product/${product.id}`)}
                      className="flex justify-between text-base font-medium text-gray-900"
                    >
                      <h3 className="flex-2">
                        <a href={product.href}>{product.name}</a>
                      </h3>
                      <p className="flex-1 justify-end text-right ml-4">
                        {product.price.toLocaleString("en-IN", {
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: "INR",
                        })}
                      </p>
                    </div>
                    <div className="flex-1 flex items-end justify-between text-sm">
                      <div className="flex">
                        <button
                          className="font-medium text-blue-600 hover:text-blue-500"
                          onClick={() => {
                            removeItem(product.id);
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
      {cartItems?.length === 0 ? (
        <div
          id="wishlist-sidebar-body"
          className="w-full flex flex-col justify-center items-center"
          style={{ height: "calc(100% - 3.5rem)" }}
        >
          <img className="h-1/2 w-1/2" src={EmptyCart} alt="Empty Cart" />
          <p className="text-gray-500 text-center mt-4">
            Your Wishlist is empty
          </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Wishlist;
