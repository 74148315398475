import React from "react";
import useAuth from "../../config/AuthContext";
import { useToken } from "../../config/useToken";

const PersonalInfo = ({ user }) => {
  const [disabled, setDisabled] = React.useState(true);
  const [firstName, setFirstName] = React.useState(user.first_name);
  const [lastName, setLastName] = React.useState(user.last_name);
  const [phoneNumber, setPhoneNumber] = React.useState(user.phone_number);
  const [gender, setGender] = React.useState(user.gender);
  const { editPersonalInfo, editUserPhone } = useToken();
  // eslint-disable-next-line no-unused-vars
  const [disabledEmail, setDisabledEmail] = React.useState(true);
  const [disabledPhone, setDisabledPhone] = React.useState(true);
  const [passDivVisible, setPassDivVisible] = React.useState(false);
  const [currentPass, setCurrentPass] = React.useState("");
  const [newPass, setNewPass] = React.useState("");
  const [confirmPass, setConfirmPass] = React.useState("");
  const { changePassword } = useAuth();

  const handleChangePass = () => {
    if (currentPass === "" || newPass === "" || confirmPass === "") {
      alert("Please fill all the fields");
    } else if (currentPass !== confirmPass) {
      alert("Current password and confirm password do not match");
    } else {
      changePassword(currentPass, newPass);
      alert("Password changed successfully");
    }
  };

  const handleEditPersonalInfo = () => {
    editPersonalInfo(firstName, lastName, gender);
    setDisabled(!disabled);
  };

  const handleEditPhoneNumber = () => {
    editUserPhone(phoneNumber);
    setDisabledPhone(!disabledPhone);
  };

  return (
    <div className="h-full w-full flex flex-col p-6">
      <div className="flex items-center mb-4">
        <h1 className="text-base font-semibold m-0">Personal Information</h1>
        <button
          className="font-semibold text-blue-700 text-sm ml-10 pt-1"
          onClick={() => {
            !disabled ? handleEditPersonalInfo() : setDisabled(!disabled);
          }}
        >
          {disabled ? "Edit" : "Save"}
        </button>
      </div>
      <form className="w-full mb-12" onSubmit={(e) => e.preventDefault()}>
        <div className="flex flex-row gap-3">
          <input
            type="text"
            disabled={disabled}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="border w-1/3 py-2 px-3 rounded-sm text-sm disabled:text-gray-500 capitalize"
          />
          <input
            type="text"
            disabled={disabled}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="border w-1/3 py-2 px-3 rounded-sm text-sm disabled:text-gray-500 capitalize"
          />
        </div>
        <h3 className="text-sm mt-7 mb-2">Your Gender</h3>
        <div className="flex flex-row gap-6 mb-12">
          <div>
            <input
              className="
                form-check-input 
                appearance-none 
                rounded-full 
                h-4 
                w-4 
                border 
                border-gray-300 
                bg-white 
                checked:bg-blue-600 
                checked:border-blue-600
                disabled:border-gray-300
                disabled:border-2
                focus:outline-none 
                transition 
                duration-200 
                mt-1 
                align-top 
                bg-no-repeat 
                bg-center 
                bg-contain 
                float-left 
                mr-2 
                cursor-pointer
              "
              type="radio"
              name="gender"
              id="male"
              value="Male"
              checked={gender === "Male"}
              disabled={disabled}
              onClick={(e) => {
                setGender("Male");
                console.log(e.target.value);
              }}
            />
            <label
              className="form-check-label inline-block text-gray-800"
              htmlFor="male"
            >
              Male
            </label>
          </div>
          <div>
            <input
              className="
                form-check-input 
                appearance-none 
                rounded-full 
                h-4 
                w-4 
                border 
                border-gray-300 
                bg-white 
                checked:bg-blue-600 
                checked:border-blue-600
                disabled:border-gray-300
                disabled:border-2
                focus:outline-none 
                transition 
                duration-200 
                mt-1 
                align-top 
                bg-no-repeat 
                bg-center 
                bg-contain 
                float-left 
                mr-2 
                cursor-pointer
              "
              type="radio"
              name="gender"
              id="female"
              value="Female"
              checked={gender === "Female"}
              disabled={disabled}
              onClick={(e) => {
                setGender("Female");
                console.log(e.target.value);
              }}
            />
            <label
              className="form-check-label inline-block text-gray-800"
              htmlFor="female"
            >
              Female
            </label>
          </div>
        </div>
        <div className="flex items-center mb-4">
          <h1 className="text-base font-semibold m-0">
            Email Address & Password
          </h1>
          <button
            className="font-semibold text-blue-700 text-sm ml-10 pt-1"
            onClick={() => setPassDivVisible(!passDivVisible)}
          >
            {passDivVisible ? "Hide" : "Change Password"}
          </button>
        </div>
        <div
          id="personalInfoPassword"
          className="flex flex-col gap-3 mb-12"
          style={passDivVisible ? { display: "flex" } : { display: "none" }}
        >
          <input
            type="password"
            placeholder="Current Password"
            onChange={(e) => setCurrentPass(e.target.value)}
            className="border w-1/3 py-2 px-3 rounded-sm text-sm disabled:text-gray-500"
          />
          <input
            type="password"
            placeholder="Re-Enter Current Password"
            onChange={(e) => setConfirmPass(e.target.value)}
            className="border w-1/3 py-2 px-3 rounded-sm text-sm disabled:text-gray-500"
          />
          <input
            type="password"
            placeholder="New Password"
            onChange={(e) => setNewPass(e.target.value)}
            className="border w-1/3 py-2 px-3 rounded-sm text-sm disabled:text-gray-500"
          />
          <button
            className="font-semibold bg-indigo-600 text-white w-1/3 px-2 rounded text-sm py-2"
            onClick={handleChangePass}
          >
            Save Changes
          </button>
        </div>
        <div id="personalInfoEmail" className="flex flex-row gap-3 mb-12">
          <input
            type="email"
            disabled={disabledEmail}
            value={user.email}
            className="border w-1/3 py-2 px-3 rounded-sm text-sm disabled:text-gray-500"
          />
        </div>
        <div className="flex items-center mb-4">
          <h1 className="text-base font-semibold m-0">Mobile Number</h1>
          <button
            className="font-semibold text-blue-700 text-sm ml-10 pt-1"
            onClick={(e) => {
              e.preventDefault();
              !disabledPhone
                ? handleEditPhoneNumber()
                : setDisabledPhone(!disabledPhone);
            }}
          >
            {disabledPhone ? "Edit" : "Save"}
          </button>
        </div>
        <div id="personalInfoMobile" className="flex flex-row gap-3">
          <input
            type="tel"
            disabled={disabledPhone}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="border w-1/3 py-2 px-3 rounded-sm text-sm disabled:text-gray-500"
          />
        </div>
      </form>
      <h1 className="font-semibold m-0 mb-6">FAQs</h1>
      <h1 className="text-sm font-medium m-0 mb-2">
        What happens when I update my email address (or mobile number)?
      </h1>
      <p className="text-sm text-gray-700 m-0 mb-4">
        Your login email id (or mobile number) changes, likewise. You'll receive
        all your account related communication on your updated email address (or
        mobile number).
      </p>
      <h1 className="text-sm font-medium m-0 mb-2">
        When will my We-Handcraft account be updated with the new email address
        (or mobile number)?
      </h1>
      <p className="text-sm text-gray-700 m-0 mb-4">
        It happens as soon as you confirm the verification code sent to your
        email (or mobile) and save the changes.
      </p>
      <h1 className="text-sm font-medium m-0 mb-2">
        What happens to my existing We-Handcraft account when I update my email
        address (or mobile number)?
      </h1>
      <p className="text-sm text-gray-700 m-0 mb-4">
        Updating your email address (or mobile number) doesn't invalidate your
        account. Your account remains fully functional. You'll continue seeing
        your Order history, saved information and personal details.
      </p>
      <h1 className="text-sm font-medium m-0 mb-2">
        Does my Seller account get affected when I update my email address?
      </h1>
      <p className="text-sm text-gray-700 m-0 mb-4">
        We-Handcraft has a 'single sign-on' policy. Any changes will reflect in
        your Seller account also.
      </p>
    </div>
  );
};

export default PersonalInfo;
