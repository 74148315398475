import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./CategoryHeader.css";
import Config from "../../config/config";

const CategoryHeader = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${Config.GLOBAL_URL}/api/header-category`)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (loading)
    return (
      <div className="bg-white min-h-7 w-full drop-shadow">
        <div className="flex sticky max-w-128 mx-auto" />
      </div>
    );

  return (
    <div
      id="sub-category-main-div"
      style={{ zIndex: 8, position: "relative", boxShadow: "0px 0px 10px -5px black", }}
      className="bg-white min-h-7 w-full drop-shadow shadow mt-2 border-slate-500"
    >
      <div className="flex sticky justify-evenly flex-row max-w-128 mx-auto ">
        {data.map((category, index) => (
          <div
            key={index}
            className="md:pl-6 py-3 px-2 h-28 categoryHeaderMenu"
            onClick={() => {
              navigate(`/category/${category.name}/${category.id}`);
            }}
          >
            <div className="mb-1 flex justify-center">
              <div className="flex justify-center items-center h-8 md:h-16 w-8 md:w-16">
                <img src={category.image} alt="" />
              </div>
            </div>
            <div className="text-sm font-medium text-center categoryHeaderMenu">
              {category.name}
              <ul className="categoryHeaderDropdown">
                {category.sub_categories.map((dat, i) => {
                  return (
                    <li
                      key={i}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/sub-category/${dat.slug}/${dat.id}`);
                      }}
                      className="flex py-4 items-center justify-start text-left"
                    >
                      {dat.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryHeader;
